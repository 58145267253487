import type { BaseSyntheticEvent } from 'react';
import type { MessageDescriptor } from 'react-intl';

export type ErrorType = 'error' | 'warning';

export type ValidationError = {
  type: ErrorType;
  message: MessageDescriptor;
};

export const onHookFormSubmit = (
  _: Record<string, unknown>,
  e: BaseSyntheticEvent | undefined,
): void => {
  if (e && typeof e.target?.submit === 'function') {
    e.target.submit();
  }
};

export const getErrorMessage = (
  messages: Record<string, ValidationError>,
  errorType?: string,
): MessageDescriptor | undefined => {
  if (!errorType) {
    return undefined;
  }

  return messages[errorType]?.message;
};

/**
 * Create a hidden form and submit it with pairs of names and values.
 * Equivalent to sending an POST request that changes the window location.
 * https://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit
 */
export const submitHiddenForm = (
  path: string,
  params: Record<string, string>,
): void => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = path;

  for (let i = 0; i < Object.keys(params).length; i += 1) {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = Object.keys(params)[i];
    hiddenField.value = params[Object.keys(params)[i]];

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);
  form.submit();
};
