import { type ModalProps, useModal } from '@sumup-oss/circuit-ui';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { config } from '../lib/config/client';

import QrCodeConfirmModal from 'components/LoginPage/QRCode/Modal';
import type { AuthenticationChallengeState } from 'components/SWK/SWKIcon';
import { submitHiddenForm } from 'services/forms';

type ChallengeEvent = {
  challenge_id: string;
  state: AuthenticationChallengeState;
  user_email?: string;
  user_id?: string;
};

export const useQRCodeChallenge = (
  loginChallenge: string,
  authenticationChallengeId = '',
): {
  challengeURL: string;
  isSubmitting: boolean;
} => {
  const [challengeId, setChallengeId] = useState(authenticationChallengeId);
  const [challengeEvent, setChallengeEvent] = useState<ChallengeEvent | null>(
    null,
  );
  const [reconnectCounter, setReconnectCounter] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setModal, removeModal } = useModal();
  const intl = useIntl();

  const regenerateChallenge = useCallback(() => {
    if (!challengeId) {
      return;
    }

    fetch(`${config.urls.apiBasePath}/login/qrcode/regenerate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        challenge: loginChallenge,
        authentication_challenge_id: challengeId,
      }),
    })
      .then((res) => res.json())
      .then(
        (res: { authentication_challenge_id: string; expires_in: number }) => {
          setChallengeId(res.authentication_challenge_id);
          setChallengeEvent(null);
        },
      )
      .catch((err) => {
        console.error(err);
      });
  }, [loginChallenge, challengeId]);

  useEffect(() => {
    if (!challengeId || isSubmitting) {
      return undefined;
    }

    const eventSource = new EventSource(
      `/flows/login/qrcode/challenges/${challengeId}/events`,
    );
    eventSource.onmessage = (event) => {
      const parsedEvent = JSON.parse(event.data as string) as ChallengeEvent;
      setChallengeEvent((prevChallengeEvent) => {
        if (!prevChallengeEvent) {
          return parsedEvent;
        }

        if (
          prevChallengeEvent.state === parsedEvent.state &&
          prevChallengeEvent.challenge_id === parsedEvent.challenge_id &&
          prevChallengeEvent.user_email === parsedEvent.user_email &&
          prevChallengeEvent.user_id === parsedEvent.user_id
        ) {
          return prevChallengeEvent;
        }

        return parsedEvent;
      });
    };
    eventSource.addEventListener('close', () => {
      // Reconnect when the connection is closed. We have a 60 seconds timeout on the ingress for portier.
      // Whereas the challenge is valid for 2 minutes, hence we need to reconnect twice.
      setReconnectCounter(reconnectCounter + 1);
    });

    return () => {
      eventSource.close();
    };
  }, [challengeId, reconnectCounter, isSubmitting]);

  useEffect(() => {
    if (!challengeEvent) {
      return;
    }

    const { state, user_email: email } = challengeEvent;
    if (state === 'expired' || state === 'canceled') {
      regenerateChallenge();
    } else if (state === 'solved') {
      if (!email) {
        return;
      }

      setModal({
        closeButtonLabel: intl.formatMessage({
          id: 'close_label',
          defaultMessage: 'Close',
          description: 'Accessibility label for closing the modal.',
        }),
        children: (
          <QrCodeConfirmModal
            email={email}
            onCancel={() => {
              regenerateChallenge();
              removeModal();
            }}
            onConfirm={() => {
              setIsSubmitting(true);
              submitHiddenForm(
                `${config.urls.apiBasePath}/login/qrcode/claim`,
                {
                  authentication_challenge_id: challengeId,
                  challenge: loginChallenge,
                },
              );
              removeModal();
            }}
          />
        ),
      } as ModalProps);
    }
  }, [
    regenerateChallenge,
    challengeEvent,
    loginChallenge,
    challengeId,
    setModal,
    removeModal,
    intl.formatMessage,
  ]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      regenerateChallenge();
    }
  }, [regenerateChallenge]);

  useEffect(() => {
    if (!challengeId) {
      return undefined;
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange, challengeId]);

  return {
    challengeURL: challengeId && `${config.dashboardBaseURL}/cl/${challengeId}`,
    isSubmitting,
  };
};
