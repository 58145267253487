import { defineMessage } from 'react-intl';

import type { ValidationError } from '../../services/forms';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MIN_LENGTH_ERROR = 'minLength';
export const PASSWORD_MIN_STRENGTH = 2;

export const ERROR_MESSAGES: Record<string, ValidationError> = {
  required: {
    message: defineMessage({
      defaultMessage: 'Password is required.',
      description: 'validation hint for missing password',
    }),
    type: 'error',
  },
  minLength: {
    message: defineMessage({
      defaultMessage: 'Choose a password with at least 8 characters.',
      description: 'validation hint for too short password',
    }),
    type: 'error',
  },
  minStrength: {
    message: defineMessage({
      defaultMessage: 'This password could be easily guessed.',
      description: 'validation hint for easily guessed password',
    }),
    type: 'error',
  },
  pwned: {
    message: defineMessage({
      defaultMessage:
        'This password was on a public list of leaked passwords. For your protection, you cannot use it here. <a>Learn more</a>.',
      description: 'error message for leaked passwords',
    }),
    type: 'error',
  },
} as const;

export const STRENGTH_METER_MESSAGES: Record<string, ValidationError> = {
  0: {
    message: defineMessage({
      defaultMessage: 'Choose a password with at least 8 characters.',
      description: 'validation hint for too short password',
    }),
    type: 'error',
  },
  1: {
    message: defineMessage({
      defaultMessage: 'This password could be easily guessed.',
      description: 'validation hint for easily guessed password',
    }),
    type: 'error',
  },
  2: {
    message: defineMessage({
      defaultMessage: 'This password could be easily guessed.',
      description: 'validation hint for easily guessed password',
    }),
    type: 'error',
  },
  3: {
    message: defineMessage({
      defaultMessage: 'This password could be stronger.',
      description: 'validation hint for password improvement',
    }),
    type: 'error',
  },
  4: {
    message: defineMessage({
      defaultMessage: 'This password is strong.',
      description: 'validation hint for strong password',
    }),
    type: 'error',
  },
  5: {
    message: defineMessage({
      defaultMessage: 'This password is very strong.',
      description: 'validation hint for very strong password',
    }),
    type: 'error',
  },
};
export const STRENGTH_METER_EXTRA_INFORMATION: Record<string, ValidationError> =
  {
    1: {
      message: defineMessage({
        defaultMessage:
          'Make it harder to guess by adding letters, numbers and special characters.',
        description:
          'validation hint for easily guessed password extra information',
      }),
      type: 'error',
    },
    2: {
      message: defineMessage({
        defaultMessage:
          'Make it harder to guess by adding letters, numbers and special characters.',
        description:
          'validation hint for easily guessed password extra information',
      }),
      type: 'error',
    },
    3: {
      message: defineMessage({
        defaultMessage:
          'Strengthen it by adding letters, numbers and special characters.',
        description:
          'validation hint for easily guessed password extra information',
      }),
      type: 'error',
    },
    4: {
      message: defineMessage({
        defaultMessage:
          'Make it very strong by adding letters, numbers and special characters.',
        description: 'validation hint for strong password extra information',
      }),
      type: 'error',
    },
    5: {
      message: defineMessage({
        defaultMessage: 'Well done',
        description:
          'validation hint for very strong password extra information',
      }),
      type: 'error',
    },
  };
