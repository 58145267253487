import {
  Body,
  Button,
  Input,
  type InputElement,
  type InputProps,
} from '@sumup-oss/circuit-ui';
import Mailcheck from 'mailcheck';
import {
  type FocusEvent,
  type FunctionComponent,
  type Ref,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import styles from './styles.module.css';

export type EmailInputProps = {
  name: string;
  useEmailSuggestion?: boolean;
  onEmailSuggestionClick?: (suggestion: string) => void;
  country?: string;
} & Partial<InputProps>;

type MailcheckSuggestion = {
  address: string;
  domain: string;
  full: string;
};

/**
 * A generic uncontrolled email input.
 */
const EmailInput: FunctionComponent<EmailInputProps> = forwardRef(
  (
    {
      country,
      onEmailSuggestionClick,
      useEmailSuggestion,
      autoComplete = 'username',
      ...inputProps
    },
    ref,
  ) => {
    const intl = useIntl();
    const [emailSuggestion, setEmailSuggestion] = useState('');

    const handleEmailSuggestion = (
      event: FocusEvent<HTMLInputElement, Element>,
    ) => {
      const email = event.target.value;

      Mailcheck.run({
        email,
        suggested: (suggested: MailcheckSuggestion) => {
          setEmailSuggestion(suggested.full);
        },
      });
    };

    const handleEmailSuggestionClick = useCallback(() => {
      if (onEmailSuggestionClick) {
        onEmailSuggestionClick(emailSuggestion);
        setEmailSuggestion('');
      }
    }, [emailSuggestion, onEmailSuggestionClick]);

    return (
      <>
        <Input
          ref={ref as Ref<InputElement>}
          label={intl.formatMessage({
            defaultMessage: 'Email address',
            description: 'email input label',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'me@example.com',
            description: 'email input placeholder',
          })}
          type="email"
          autoComplete={autoComplete}
          {...inputProps}
          onFocus={(event) => {
            if (useEmailSuggestion) {
              setEmailSuggestion('');
            }

            if (inputProps.onFocus) {
              inputProps.onFocus(
                event as FocusEvent<HTMLInputElement, Element>,
              );
            }
          }}
          onBlur={(event) => {
            if (useEmailSuggestion) {
              handleEmailSuggestion(
                event as FocusEvent<HTMLInputElement, Element>,
              );
            }

            if (inputProps.onBlur) {
              inputProps.onBlur(event as FocusEvent<HTMLInputElement, Element>);
            }
          }}
        />
        {emailSuggestion && (
          <Body variant="subtle" className={styles.body}>
            {intl.formatMessage(
              {
                defaultMessage: 'Did you mean { suggestion }?',
                description: 'email suggestion label',
              },
              {
                suggestion: (
                  <Button
                    className={styles.button}
                    type="button"
                    variant="tertiary"
                    onClick={handleEmailSuggestionClick}
                  >
                    {emailSuggestion}
                  </Button>
                ),
              },
            )}
          </Body>
        )}
      </>
    );
  },
);

EmailInput.displayName = 'EmailInput';

export default EmailInput;
