import { Body } from '@sumup-oss/circuit-ui';
import { useIntl } from 'react-intl';
import { QRCode } from 'react-qrcode-logo';

import styles from './styles.module.css';

type QRCodeProps = {
  challengeURL: string;
};

export default function Code({ challengeURL }: QRCodeProps) {
  const intl = useIntl();

  return (
    <div className={styles.qrCodeColumn}>
      <div className={styles.loginWithQRCodeContainer}>
        <div className={styles.qrCanvasContainer}>
          <QRCode
            value={challengeURL}
            logoImage="https://circuit.sumup.com/icons/v2/sum_up_logomark_24.svg"
            removeQrCodeBehindLogo={true}
            logoPadding={2}
            logoHeight={40}
            logoWidth={40}
            quietZone={5}
            ecLevel="H"
            size={180}
            aria-label={intl.formatMessage({
              defaultMessage: 'QR code for login',
              description: 'Describes the QR code.',
            })}
            aria-live="polite"
          />
        </div>
      </div>
      <Body size="one" className={styles.bodyMarginTop} variant="highlight">
        {intl.formatMessage({
          defaultMessage: 'Scan the QR code to log in',
          description: 'Title that prompts for scanning the qr code.',
        })}
      </Body>
      <Body size="two" className={styles.bodyMarginTopSmall} variant="subtle">
        {intl.formatMessage(
          {
            defaultMessage:
              'Then use the SumUp app or {dashboardLink} to confirm.',
            description:
              'Description on how to use the QR code for logging in.',
          },
          {
            dashboardLink: 'me.sumup.com',
          },
        )}
      </Body>
    </div>
  );
}
