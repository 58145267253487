import { Hide, View } from '@sumup-oss/icons';
import type { FC, MouseEventHandler } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import styles from './styles.module.css';

const showPasswordAriaLabel = defineMessage({
  defaultMessage: 'Show password',
  description:
    'Button that turns on password visibility in a password input field.',
});

const hidePasswordAriaLabel = defineMessage({
  defaultMessage: 'Hide password',
  description:
    'Button that turns off password visibility in a password input field.',
});

export type PasswordVisibilityToggleProps = {
  visible: boolean;
  onShowClick: MouseEventHandler;
  className?: string;
};

export const PasswordVisibilityToggle: FC<PasswordVisibilityToggleProps> = ({
  visible,
  onShowClick,
  className,
}) => {
  const intl = useIntl();
  return (
    <div className={`${className ?? ''} ${styles.visibilityToggleContainer}`}>
      <button
        data-testid="password-visibility-toggle"
        type="button"
        role="switch"
        aria-checked={visible}
        aria-label={
          visible
            ? intl.formatMessage(hidePasswordAriaLabel)
            : intl.formatMessage(showPasswordAriaLabel)
        }
        onClick={onShowClick}
        // skip password visibility toggle when navigating using tab
        // accessibility for password input is provided by the user-agents themselves
        tabIndex={-1}
        className={styles.resetButton}
      >
        {visible ? (
          <Hide
            role="img"
            aria-label={intl.formatMessage({
              defaultMessage: 'A struck-through eyeball',
              description: 'Title of an svg icon for accessibility purposes.',
            })}
            style={{ display: 'block' }}
          />
        ) : (
          <View
            role="img"
            aria-label={intl.formatMessage({
              defaultMessage: 'An eyeball',
              description: 'Title of an svg icon for accessibility purposes.',
            })}
            style={{ display: 'block' }}
          />
        )}
      </button>
    </div>
  );
};
