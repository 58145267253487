import {
  Input,
  type InputElement,
  type InputProps,
} from '@sumup-oss/circuit-ui';
import {
  type FunctionComponent,
  type Ref,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { useBrowserRendered } from '../../hooks/useBrowserRendered';

import { PasswordVisibilityToggle } from './PasswordVisibilityToggle';

export type PasswordInputProps = {
  label?: string;
  showAsInvalid?: boolean;
  // https://developer.apple.com/password-rules/
  passwordrules?: string;
} & Partial<InputProps>;

const defaultLabel = defineMessage({
  defaultMessage: 'Password',
  description: 'password input label',
});

/**
 * A generic uncontrolled password input.
 */
const PasswordInput: FunctionComponent<PasswordInputProps> = forwardRef(
  ({ label, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const clientHasRendered = useBrowserRendered();
    const intl = useIntl();

    // render show & hide password control only in browsers with javascript support
    const passwordVisibilityToggle = useMemo(
      () =>
        clientHasRendered
          ? ({ className: cn }: { className?: string }) => (
              <PasswordVisibilityToggle
                visible={showPassword}
                onShowClick={() => setShowPassword(!showPassword)}
                className={cn}
              />
            )
          : () => undefined,
      [showPassword, clientHasRendered],
    );

    return (
      <Input
        ref={ref as Ref<InputElement>}
        label={label || intl.formatMessage(defaultLabel)}
        type={showPassword ? 'text' : 'password'}
        renderSuffix={passwordVisibilityToggle}
        {...props}
      />
    );
  },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
