import { Body, ButtonGroup, Headline } from '@sumup-oss/circuit-ui';
import { useIntl } from 'react-intl';

type QrCodeConfirmModalProps = {
  email: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export default function QrCodeConfirmModal({
  email,
  onCancel,
  onConfirm,
}: QrCodeConfirmModalProps) {
  const intl = useIntl();

  return (
    <>
      <Headline
        as="h2"
        size="three"
        style={{ marginBottom: 'var(--cui-spacings-giga)' }}
      >
        {intl.formatMessage({
          id: 'qr_code.modal.headline',
          defaultMessage: 'Confirm login',
          description:
            'Text shown as the headline in the QR code confirmation modal.',
        })}
      </Headline>
      <Body
        style={{
          marginBottom: 'var(--cui-spacings-giga)',
        }}
        variant="subtle"
      >
        {intl.formatMessage(
          {
            id: 'qr_code.modal.body',
            defaultMessage: "You're logging in as {email}.",
            description: 'Text shown in the QR code confirmation modal body.',
          },
          { email },
        )}
      </Body>
      <ButtonGroup
        align="center"
        actions={{
          primary: {
            children: intl.formatMessage({
              id: 'qr_code.modal.login',
              defaultMessage: 'Log in',
              description: 'Label for the button confirming the login.',
            }),
            style: {
              width: '100%',
            },
            onClick: onConfirm,
          },
          secondary: {
            children: intl.formatMessage({
              id: 'qr_code.modal.cancel',
              defaultMessage: 'Cancel',
              description: 'Label for the button declining a qr code login.',
            }),
            style: {
              width: '100%',
            },
            onClick: onCancel,
          },
        }}
      />
    </>
  );
}
