import { elb } from '@elbwalker/walker.js';
import {
  type CredentialRequestOptionsJSON,
  get as getCredential,
  parseRequestOptionsFromJSON,
  supported as webauthnSupported,
} from '@github/webauthn-json/browser-ponyfill';
import {
  Anchor,
  ButtonGroup,
  type ButtonGroupProps,
  type ClickEvent,
  NotificationInline,
} from '@sumup-oss/circuit-ui';
import {
  type BaseSyntheticEvent,
  type FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useBrowserRendered } from '../../../hooks/useBrowserRendered';
import { config } from '../../../lib/config/client';
import type { ServerError } from '../../../services/auth';
import {
  getErrorMessage,
  onHookFormSubmit,
  submitHiddenForm,
} from '../../../services/forms';
import type { AuthError } from '../../../services/login';
import {
  ERROR_MESSAGES as EMAIL_ERROR_MESSAGES,
  EMAIL_PATTERN,
  EmailInput,
} from '../../EmailInput';
import {
  ERROR_MESSAGES as PASSWORD_ERROR_MESSAGES,
  PasswordInput,
} from '../../PasswordInput';

import styles from './styles.module.css';

// Stubbing support for `isConditionalMediationAvailable` as it doesn't seem to be present in typescript types.
// See: https://developer.mozilla.org/en-US/docs/Web/API/PublicKeyCredential/isConditionalMediationAvailable
interface PublicKeyCredentialFuture extends PublicKeyCredential {
  isConditionalMediationAvailable?: () => Promise<boolean>;
}

export interface LoginFormProps {
  // The URL to submit the login form to
  submitURL: string;
  // An error displayed as inline message in the form. This can be a submission error or
  // an error from the server, for example when the login_challenge query parameter
  // is missing.
  formError?: AuthError | ServerError;
  // The login challenge to be submitted to the backend as part of the form.
  challenge: string;
  // The URL to the password reset screen.
  passwordResetURL: string;
  // The initial value of email input field.
  initialEmail?: string;
  // A submission callback that can be used for side effects like metrics or tracking.
  onSubmit?: () => void;
  // Webauthn credential assertion request as a JSON string.
  webauthnAssertion?: string;
  // Webauthn token that identifies the ongoing webauthn session automatically created
  // when requesting the login page.
  webauthnToken?: string;
  // A flag to display the loading spinner in the button when the challenge was solved by the QR Code login method.
  qrCodeSubmissionLoading?: boolean;
}

type LoginFormFields = {
  challenge: string;
  username: string;
  password: string;
  locale?: string;
};

/**
 * An uncontrolled login form.
 */
export const LoginForm: FunctionComponent<LoginFormProps> = ({
  challenge,
  formError,
  submitURL,
  passwordResetURL,
  initialEmail,
  onSubmit,
  webauthnAssertion,
  webauthnToken,
  qrCodeSubmissionLoading,
}) => {
  const intl = useIntl();
  const isBrowser = useBrowserRendered();

  // conditional mediation (for passkeys) available
  const [cmaSupported, setCmaSupported] = useState<boolean>(false);
  const [cmaAbortController, setCmaAbortController] =
    useState<AbortController | null>(null);
  const [credentialOptions, setCredentialOptions] =
    useState<CredentialRequestOptions | null>(null);
  const [webauthnPending, setWebauthnPending] = useState<boolean>(false);
  const [webauthnSubmitting, setWebauthnSubmitting] = useState<boolean>(false);
  const [webauthnError, setWebauthnError] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    setValue,
    formState: {
      isSubmitSuccessful,
      isSubmitting,
      isDirty,
      errors: { username: emailError, password: passwordError },
    },
  } = useForm<LoginFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      username: initialEmail || '',
      password: '',
      challenge,
      locale: intl.locale,
    },
    delayError: 500,
    shouldUseNativeValidation: !isBrowser,
  });

  useEffect(() => {
    setValue('locale', intl.locale);
  }, [setValue, intl.locale]);

  // on load, prepare everything for passkey login
  useEffect(() => {
    const setupPasskeyData = async (assertion: string) => {
      if (webauthnSupported()) {
        const requestOptions = parseRequestOptionsFromJSON(
          JSON.parse(assertion) as CredentialRequestOptionsJSON,
        );
        setCredentialOptions(requestOptions);

        const globalPublicKeyCredential =
          window.PublicKeyCredential as unknown as PublicKeyCredentialFuture;

        if (
          globalPublicKeyCredential.isConditionalMediationAvailable &&
          (await globalPublicKeyCredential.isConditionalMediationAvailable())
        ) {
          setCmaSupported(true);

          setCredentialOptions(requestOptions);
        }
      }
    };

    if (webauthnAssertion && webauthnToken && webauthnSupported()) {
      void setupPasskeyData(webauthnAssertion);
    }
  }, [webauthnAssertion, webauthnToken]);

  // runs conditional mediation if supported, conditional mediation allow auto-complete
  // for login form fields for passkey
  const conditionalMediation = useCallback(
    async (token: string) => {
      if (!credentialOptions || !cmaSupported) {
        return;
      }

      if (cmaAbortController) {
        cmaAbortController.abort();
      }

      try {
        // biome-ignore lint/suspicious/noImplicitAnyLet: let signal: AbortSignal | undefined;
        let signal;
        if (AbortController) {
          const controller = new AbortController();
          setCmaAbortController(controller);
          signal = controller.signal;
        }

        // A conditional get() call does not show the browser UI and remains pending until the user picks an account to sign-in with from available autofill suggestions:
        // - If the user makes a gesture outside of the dialog, it closes without resolving or rejecting the Promise and without causing a user-visible error condition.
        // - If the user selects a credential, that credential is returned to the caller.
        const credential = await getCredential({
          ...credentialOptions,
          mediation: 'conditional',
          signal,
        });

        setWebauthnSubmitting(true);
        const loginURL = `${config.urls.apiBasePath}/login`;
        submitHiddenForm(loginURL, {
          challenge,
          webauthn_token: token,
          webauthn_response: JSON.stringify(credential.toJSON()),
        });
      } catch (error) {
        // user chose not to use passkey, don't show error
        if (
          error instanceof Error &&
          error.name !== 'AbortError' &&
          error.name !== 'NotAllowedError'
        ) {
          setWebauthnError(true);
        }
      } finally {
        setWebauthnSubmitting(false);
      }
    },
    [challenge, credentialOptions, cmaSupported],
  );

  // if conditional mediation is supported, trigger it
  useEffect(() => {
    if (credentialOptions && cmaSupported && webauthnToken) {
      void conditionalMediation(webauthnToken);
    }
  }, [credentialOptions, cmaSupported, webauthnToken, conditionalMediation]);

  // handles manual trigger of the passkey if conditional mediation isn't supported or users aborts it and
  // uses passkey manually instead
  const usePasskey = useCallback(
    async (e: ClickEvent) => {
      e.preventDefault();

      // sanity check, should never happen as the button isn't shown if webauthn isn't supported
      if (!credentialOptions) {
        return;
      }

      if (cmaAbortController) {
        cmaAbortController.abort();
      }

      try {
        setWebauthnPending(true);
        const credential = await getCredential(credentialOptions);

        setWebauthnSubmitting(true);
        const loginURL = `${config.urls.apiBasePath}/login`;
        submitHiddenForm(loginURL, {
          challenge,
          webauthn_token: webauthnToken!,
          webauthn_response: JSON.stringify(credential.toJSON()),
        });
      } catch (error) {
        if (error instanceof Error && error.name !== 'NotAllowedError') {
          setWebauthnError(true);
        }
        // allow the user again to auto-fill
        void conditionalMediation(webauthnToken!);
      } finally {
        setWebauthnSubmitting(false);
        setWebauthnPending(false);
      }
    },
    [challenge, credentialOptions, cmaAbortController, webauthnToken],
  );

  const passwordErrorMessage = getErrorMessage(
    PASSWORD_ERROR_MESSAGES,
    passwordError?.type,
  );

  const emailErrorMessage = getErrorMessage(
    EMAIL_ERROR_MESSAGES,
    emailError?.type,
  );

  const handleSubmitCallback = useCallback(
    (_: Record<string, unknown>, e: BaseSyntheticEvent | undefined) => {
      elb('button clicked', {
        business_flow: 'login',
        button_description: 'login',
      });

      if (typeof onSubmit !== 'undefined') {
        onSubmit();
      }
      onHookFormSubmit(_, e);
    },
    [onSubmit],
  );

  const getActions = (): ButtonGroupProps['actions'] => {
    const actions = {
      primary: {
        children: intl.formatMessage({
          defaultMessage: 'Next',
          description: 'login form button text',
        }),
        type: 'submit' as HTMLButtonElement['type'],
        isLoading:
          isSubmitting ||
          isSubmitSuccessful ||
          qrCodeSubmissionLoading ||
          webauthnSubmitting,
        loadingLabel: intl.formatMessage({
          defaultMessage: 'Logging in',
          description:
            'Label for the spinner that is displayed when the login form is being submitted.',
        }),
      },
    };
    if (credentialOptions) {
      return {
        secondary: {
          children: intl.formatMessage({
            id: 'login.passkey.button',
            defaultMessage: 'Log in with a passkey',
            description: 'Button to attempt a login using a Passkey',
          }),
          onClick: usePasskey,
          isLoading: webauthnPending || webauthnSubmitting,
          loadingLabel: intl.formatMessage({
            defaultMessage: 'Waiting for input from browser interaction...',
            description:
              'Message shown when waiting for the user to use their browser-based credentials',
          }),
        },
        ...actions,
      };
    }
    return actions;
  };

  return (
    <form
      autoComplete="on"
      action={submitURL}
      method="POST"
      noValidate={isBrowser}
      onSubmit={handleSubmit(handleSubmitCallback)}
      className={styles.formContainer}
    >
      {!isDirty && formError && (
        <NotificationInline
          body={intl.formatMessage(formError.message)}
          variant={formError.variant ? formError.variant : 'warning'}
          className={styles.notification}
        />
      )}
      <input type="hidden" {...register('challenge')} />
      <input type="hidden" {...register('locale')} />
      <EmailInput
        className={styles.bottomSpacing}
        invalid={!!emailError}
        autoComplete={cmaSupported ? 'username webauthn' : 'username'}
        validationHint={
          emailErrorMessage && intl.formatMessage(emailErrorMessage)
        }
        {...register('username', {
          required: true,
          pattern: EMAIL_PATTERN,
        })}
      />
      <PasswordInput
        data-testid="password-input"
        className={styles.passwordInput}
        autoComplete={
          cmaSupported ? 'current-password webauthn' : 'current-password'
        }
        invalid={!!passwordError}
        validationHint={
          passwordErrorMessage && intl.formatMessage(passwordErrorMessage)
        }
        {...register('password', {
          required: true,
        })}
      />
      <Anchor
        href={passwordResetURL}
        onClick={() => {
          elb('button clicked', {
            business_flow: 'login',
            button_description: 'forgot_your_password',
          });
        }}
        size="one"
        variant="highlight"
        className={styles.bottomSpacing}
      >
        {intl.formatMessage({
          defaultMessage: 'Forgot password?',
          description: 'forgot password link',
        })}
      </Anchor>
      {webauthnError && (
        <NotificationInline
          body="Passkey authentication failed"
          variant="danger"
          className={styles.webauthnNotification}
        />
      )}
      <ButtonGroup
        className={styles.buttonGroup}
        actions={getActions()}
        align="right"
      />
    </form>
  );
};
