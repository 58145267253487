import { defineMessage } from 'react-intl';

import type { ValidationError } from '../../services/forms';

export const EMAIL_PATTERN = new RegExp(
  "(([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]\\.?)+" +
    '@' +
    '[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?' +
    '(?:\\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+)',
);

export const ERROR_MESSAGES: Record<string, ValidationError> = {
  required: {
    message: defineMessage({
      defaultMessage: 'Email is required.',
      description: 'validation hint for missing e-mail',
    }),
    type: 'error',
  },
  pattern: {
    message: defineMessage({
      defaultMessage: 'Email is invalid.',
      description: 'validation hint for invalid e-mail format',
    }),
    type: 'error',
  },
} as const;
